export enum ERROR_CODES {
  EXPLORER_WRONG_DIMENSION = 'EXPLORER_WRONG_DIMENSION',
  EXPLORER_INCOMPATIBLE_TIME_DIMENSIONS = 'EXPLORER_INCOMPATIBLE_TIME_DIMENSIONS',
  EXPLORER_INEXISTENT_DRILLDOWN_PERIOD = 'EXPLORER_INEXISTENT_DRILLDOWN_PERIOD',
  EXPLORER_INVALID_DRILLDOWN_PERIOD = 'EXPLORER_INVALID_DRILLDOWN_PERIOD',

  ANALYTICS_INAPPLICABLE_MEASURES = 'ANALYTICS_INAPPLICABLE_MEASURES',
  ANALYTICS_INAPPLICABLE_DIMENSIONS = 'ANALYTICS_INAPPLICABLE_DIMENSIONS',
  ANALYTICS_PERMISSION_DENIED = 'ANALYTICS_PERMISSION_DENIED',
  ANALYTICS_MEMBER_NOT_FOUND = 'ANALYTICS_MEMBER_NOT_FOUND',
  ANALYTICS_INAPPLICABLE_FILTERS = 'ANALYTICS_INAPPLICABLE_FILTERS',
  ANALYTICS_INAPPLICABLE_TEMPORAL_DIMENSION = 'ANALYTICS_INAPPLICABLE_TEMPORAL_DIMENSION',

  BENCHMARK_BEFORE_AVAILABILITY_DATE = 'BENCHMARK_BEFORE_AVAILABILITY_DATE',
  BENCHMARK_NOT_PAST_PERIOD = 'BENCHMARK_NOT_PAST_PERIOD',
  BENCHMARK_PERIOD_NOT_ADAPTED = 'BENCHMARK_PERIOD_NOT_ADAPTED',
  BENCHMARK_SEGMENT_TOO_RESTRICTIVE = 'BENCHMARK_SEGMENT_TOO_RESTRICTIVE',
  BENCHMARK_UNAVAILABLE_MEASURE = 'BENCHMARK_UNAVAILABLE_MEASURE',

  FILE_UPLOAD_WRONG_TYPE_OR_SIZE = 'FILE_UPLOAD_WRONG_TYPE_OR_SIZE'
}

const CUSTOM_API_ERROR_KEYS = {
  [ERROR_CODES.ANALYTICS_PERMISSION_DENIED]:
    'd20690a1-cbf3-4e58-994a-542bb5a43354',
  [ERROR_CODES.ANALYTICS_MEMBER_NOT_FOUND]:
    '69ddd569-c3bc-4937-b817-3c22780f8ad0',
  [ERROR_CODES.ANALYTICS_INAPPLICABLE_FILTERS]:
    '1715f252-b3b6-4707-82a5-0986700b43e4',
  [ERROR_CODES.ANALYTICS_INAPPLICABLE_MEASURES]:
    '717090bf-ab79-445c-b27d-9749c9cbabae',
  [ERROR_CODES.ANALYTICS_INAPPLICABLE_DIMENSIONS]:
    'b8b47542-76fe-4af7-ba66-34024957da0a',

  [ERROR_CODES.BENCHMARK_BEFORE_AVAILABILITY_DATE]:
    '3f998a6b-4c3d-4f14-972e-5ae70e0e6525',
  [ERROR_CODES.BENCHMARK_NOT_PAST_PERIOD]:
    '0c57d582-2bac-4332-aec0-650b94814256',
  [ERROR_CODES.BENCHMARK_PERIOD_NOT_ADAPTED]:
    '035764dc-403e-4cbe-a03c-507e120e253d',
  [ERROR_CODES.BENCHMARK_SEGMENT_TOO_RESTRICTIVE]:
    'f6cbb813-6b0d-4877-af1f-7431ca3de88e',
  [ERROR_CODES.BENCHMARK_UNAVAILABLE_MEASURE]:
    '9e5f8895-577a-4fcc-a492-bf3a8e944d45',

  [ERROR_CODES.FILE_UPLOAD_WRONG_TYPE_OR_SIZE]:
    'be524233-f1d8-4c32-b94e-11a828488bd5'
}

export const CUSTOM_QUERY_RENDERER_ERROR_KEYS = {
  [ERROR_CODES.ANALYTICS_INAPPLICABLE_TEMPORAL_DIMENSION]:
    'cdb6a1a7-e678-40aa-ae99-0cb0bde55a2d'
}

export const EXPLORER_ERROR_KEYS = {
  [ERROR_CODES.EXPLORER_WRONG_DIMENSION]:
    '98e8cb5d-c8b8-4fd8-845e-490fddc84f5a',
  [ERROR_CODES.EXPLORER_INCOMPATIBLE_TIME_DIMENSIONS]:
    '7875930f-2198-4cab-9157-16ef63642618',
  [ERROR_CODES.EXPLORER_INEXISTENT_DRILLDOWN_PERIOD]:
    'a7e3af89-5fc8-4185-b592-dba027ca1ea4',
  [ERROR_CODES.EXPLORER_INVALID_DRILLDOWN_PERIOD]:
    'dbc154b5-8196-48bf-b73c-30cbcef904a0'
}

export const ERROR_KEYS_BY_CODE: { [key in ERROR_CODES]: string } = {
  ...EXPLORER_ERROR_KEYS,
  ...CUSTOM_API_ERROR_KEYS,
  ...CUSTOM_QUERY_RENDERER_ERROR_KEYS
}

export const ERROR_DETAILS_BY_CODE: {
  [key in ERROR_CODES]: { icon: string; messageKey: string }
} = {
  [ERROR_CODES.ANALYTICS_PERMISSION_DENIED]: {
    icon: 'icon-error-permission',
    messageKey: 'dashboard.error.permission'
  },
  [ERROR_CODES.ANALYTICS_MEMBER_NOT_FOUND]: {
    icon: 'icon-error',
    messageKey: 'dashboard.error.wrongRequest'
  },
  [ERROR_CODES.ANALYTICS_INAPPLICABLE_FILTERS]: {
    icon: 'icon-error-filter',
    messageKey: 'dashboard.error.inapplicableFilters'
  },
  [ERROR_CODES.EXPLORER_WRONG_DIMENSION]: {
    icon: 'icon-error-cube',
    messageKey: 'dashboard.error.wrongDimension'
  },
  [ERROR_CODES.EXPLORER_INCOMPATIBLE_TIME_DIMENSIONS]: {
    icon: 'icon-error-cube',
    messageKey: 'dashboard.error.incompatibleTimeDimensions'
  },
  [ERROR_CODES.EXPLORER_INEXISTENT_DRILLDOWN_PERIOD]: {
    icon: 'icon-error',
    messageKey: 'dashboard.error.inexistentPeriod'
  },
  [ERROR_CODES.EXPLORER_INVALID_DRILLDOWN_PERIOD]: {
    icon: 'icon-error',
    messageKey: 'dashboard.error.invalidDrilldownPeriod'
  },
  [ERROR_CODES.ANALYTICS_INAPPLICABLE_MEASURES]: {
    icon: 'icon-error-cube',
    messageKey: 'dashboard.error.inapplicableMeasures'
  },
  [ERROR_CODES.ANALYTICS_INAPPLICABLE_DIMENSIONS]: {
    icon: 'icon-error-cube',
    messageKey: 'dashboard.error.inapplicableDimensions'
  },
  [ERROR_CODES.BENCHMARK_BEFORE_AVAILABILITY_DATE]: {
    icon: 'icon-error-date',
    messageKey: 'benchmark.error.beforeAvailabilityDate'
  },
  [ERROR_CODES.BENCHMARK_NOT_PAST_PERIOD]: {
    icon: 'icon-error-date',
    messageKey: 'benchmark.error.notPastPeriod'
  },
  [ERROR_CODES.BENCHMARK_PERIOD_NOT_ADAPTED]: {
    icon: 'icon-error-date',
    messageKey: 'benchmark.error.periodNotAdapted'
  },
  [ERROR_CODES.BENCHMARK_SEGMENT_TOO_RESTRICTIVE]: {
    icon: 'icon-error-bench',
    messageKey: 'benchmark.error.segmentTooRestrictive'
  },
  [ERROR_CODES.BENCHMARK_UNAVAILABLE_MEASURE]: {
    icon: 'icon-error',
    messageKey: 'benchmark.error.unavailableMeasure'
  },
  [ERROR_CODES.FILE_UPLOAD_WRONG_TYPE_OR_SIZE]: {
    icon: 'icon-error',
    messageKey: 'settings.integrations.dataSources.error.wrongTypeOrSize'
  },
  [ERROR_CODES.ANALYTICS_INAPPLICABLE_TEMPORAL_DIMENSION]: {
    icon: 'icon-error-cube',
    messageKey: 'dashboard.error.inapplicableTemporalDimensions'
  }
}
