<template>
  <div
    class="root"
    :class="{
      'root--scroll': isFullPage
    }"
  >
    <template v-if="!isMobileOrTablet">
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>

      <SideMenu
        v-if="!isFullPage"
        :empty="isEmpty"
        class="menu"
        :class="{
          'menu--greyed-out': isMenuGreyedOut
        }"
      />
    </template>

    <MobileDefaultScreen v-else />

    <div id="action" />
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia'
import { useLayoutStore } from '~/stores/layout'
import { useCurrentUserStore } from '~/stores/currentUser'

export default {
  name: 'App',
  data(): { isMobileOrTablet: boolean } {
    return {
      isMobileOrTablet: false
    }
  },
  computed: {
    ...mapState(useLayoutStore, ['theme']),
    ...mapState(useCurrentUserStore, ['id']),
    isEmpty() {
      return !this.id
    },
    isMenuGreyedOut() {
      return ['subpage', 'edition', 'explore'].includes(this.theme)
    },
    isFullPage() {
      return ['full-page'].includes(this.theme)
    }
  },
  created() {
    this.isMobileOrTablet = this.computeIsMobileOrTablet()
  },
  mounted() {
    window.addEventListener('resize', this.refreshIsMobileOrTablet)
  },
  unmounted() {
    window.removeEventListener('resize', this.refreshIsMobileOrTablet)
  },
  methods: {
    refreshIsMobileOrTablet() {
      this.isMobileOrTablet = this.computeIsMobileOrTablet()
    },
    computeIsMobileOrTablet() {
      // This breakpoint matches the one defined in _variables.scss file
      if (window.innerWidth <= 954) {
        return true
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.root {
  @include responsive-zoom();
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  height: 100%;

  &--scroll {
    overflow: auto;
  }
}

@media print {
  .root {
    overflow: visible;
  }
}

.menu {
  z-index: 2;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: $side-width;

  &--greyed-out {
    z-index: 1;
  }
}
</style>

<style lang="scss">
.page-enter-active,
.page-leave-active {
  transition: all $short-transition;
}

.layout-enter-active,
.layout-leave-active {
  transition: all $short-transition;
}
</style>
