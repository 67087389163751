import { FETCH_RENAMINGS_QUERY } from '~/gql/renamings/fetchRenamings'
import type { Renaming, RenamingField } from '~/types/renaming'

interface State {
  renamings: Renaming[] | null
}

export const useRenamingStore = defineStore('renaming', {
  state: (): State => ({
    renamings: null
  }),
  getters: {
    getRenamingByField: state => {
      return (field: RenamingField) =>
        state.renamings &&
        state.renamings.find(renaming => renaming.field === field)?.naming
    }
  },
  actions: {
    async loadRenamings(shouldRefresh: boolean = false) {
      if (this.renamings && !shouldRefresh) {
        return
      }

      const app = useNuxtApp()

      this.renamings = (
        await app.$apiGqlClient.query({
          query: FETCH_RENAMINGS_QUERY
        })
      ).data.getRenamings as Renaming[]
    }
  }
})
