<template>
  <div class="reflect-field">
    <div
      v-if="withLabel || error"
      class="reflect-field__top"
    >
      <label
        v-if="withLabel"
        :for="name"
        class="reflect-field__label"
      >
        {{ label }}
        <span
          v-if="required"
          class="reflect-field__label--required"
        >
          *
        </span>
      </label>
      <p
        v-if="error && errorMessage"
        class="reflect-field__error"
      >
        {{ errorMessage }}
      </p>
    </div>
    <div class="reflect-field__container">
      <input
        v-if="type !== 'textarea' && type !== 'file' && type !== 'color'"
        :id="name"
        ref="inputRef"
        v-model="internalValue"
        :disabled="disabled"
        :name="name"
        :type="type"
        :step="step"
        :maxlength="maxlength"
        :required="required"
        spellcheck="false"
        :readonly="isReadOnly"
        :autofocus="autofocus"
        :placeholder="placeholder"
        :autocomplete="autocomplete"
        :style="icon && { 'padding-right': '38px' }"
        :class="[
          isReadOnly && 'reflect-field__input--readonly',
          (name === 'fileName' || pointer) && 'reflect-field__input--pointer',
          error && 'reflect-field__input--error',
          withBorder && 'reflect-field__input--with-border',
          disabled && 'reflect-field__input--disabled'
        ]"
        class="reflect-field__input"
        @input="emitInput"
        @blur="handleBlur"
        @focus="handleFocus"
        @keyup.enter="handleEnter"
      />
      <textarea
        v-if="type === 'textarea'"
        :id="name"
        v-model="internalValue"
        :name="name"
        :type="type"
        :maxlength="maxlength"
        spellcheck="false"
        :readonly="isReadOnly"
        :placeholder="placeholder"
        :style="{ height: `${textareaHeight}px` }"
        class="reflect-field__input reflect-field__input--textarea"
        :class="[
          isReadOnly && 'reflect-field__input--readonly',
          (name === 'fileName' || pointer) && 'reflect-field__input--pointer',
          error && 'reflect-field__input--error'
        ]"
        @input="emitInput"
      />
      <slot v-if="type === 'file' || type === 'color'" />
      <i
        v-if="type !== 'textarea' && icon"
        class="reflect-field__icon"
        :class="`icon-${icon}`"
      />
    </div>
  </div>
</template>

<script lang="ts">
import type { SlotsType } from 'vue'

export default {
  name: 'ReflectField',
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    withLabel: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      default: ''
    },
    modelValue: {
      type: String,
      default: ''
    },
    error: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: true
    },
    maxlength: {
      type: Number,
      default: 524288
    },
    step: {
      type: Number,
      default: null
    },
    textareaHeight: {
      type: Number,
      default: 40
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    validate: {
      type: Function,
      default: () => true
    },
    errorMessage: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    isReadOnly: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    pointer: {
      type: Boolean,
      default: false
    },
    withBorder: {
      type: Boolean,
      default: true
    },
    autocomplete: {
      type: String,
      default: 'off'
    }
  },
  slots: Object as SlotsType<{
    default: {}
  }>,
  emits: ['update:modelValue', 'validateField', 'blur', 'focus', 'enter'],
  setup() {
    const inputRef = ref<InstanceType<typeof HTMLInputElement> | null>(null)

    return {
      inputRef
    }
  },
  data(): { internalValue: string } {
    return {
      internalValue: ''
    }
  },
  computed: {
    isValueValid() {
      let isValid = false
      if (this.modelValue !== '') {
        if (this.validate) {
          isValid = this.validate(this.modelValue)
        } else {
          isValid = true
        }
      } else if (this.internalValue !== '') {
        if (this.validate) {
          isValid = this.validate(this.internalValue)
        } else {
          isValid = true
        }
      }
      return isValid
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newValue) {
        this.internalValue = newValue
      }
    }
  },
  mounted() {
    if (this.autofocus) {
      this.inputRef!.focus()
    }
  },
  methods: {
    emitInput(event: Event) {
      this.$emit('update:modelValue', (event.target as HTMLInputElement).value)
    },
    isFieldValid() {
      if (this.isReadOnly) {
        return
      }
      this.$emit('validateField', this.name, this.isValueValid)
    },
    handleBlur() {
      this.$emit('blur')
      this.isFieldValid()
    },
    handleFocus() {
      this.$emit('focus')
    },
    handleEnter() {
      this.$emit('enter')
    }
  }
}
</script>

<style scoped lang="scss">
$field-border: 1px;

.reflect-field {
  display: flex;
  flex-direction: column;

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 4px;
  }

  &__label {
    @include font-text;
    @include font-size($font-size-mini);
    color: $text-primary;

    &--required {
      color: $color-error;
    }
  }

  &__error {
    @include font-text;
    @include font-size($font-size-mini);
    color: $color-error;
    text-align: right;
    flex: 1;
  }

  &__container {
    display: flex;
    flex: 1;
    position: relative;
  }

  &__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
    @include font-icon;
    @include font-size(16px);
    color: $text-tertiary;
  }

  &__input {
    flex: 1;
    height: $field-height;
    padding: $margin-mini $margin-small;
    border-radius: 8px;
    box-sizing: border-box;
    @include font-text;
    @include font-size($field-font-size);
    background-color: $field-background;
    color: $text-primary;
    transition: all $short-transition ease;
    border: unset;

    &::placeholder {
      color: $text-tertiary;
    }

    &:focus,
    &:focus-visible {
      outline: none;
      border: none;
    }

    &--with-border {
      border: $field-border solid $border-primary;
      border-radius: 4px;

      &:focus {
        color: $text-primary;
        box-shadow: 0 0 0 1px $text-primary;
        outline: none;
      }
    }

    &--error:not(:focus) {
      border-color: $color-error;
    }

    &--readonly {
      cursor: default;

      &:focus {
        box-shadow: none;
      }
    }

    &--pointer {
      cursor: pointer;
    }

    &--textarea {
      padding: $margin-mini $margin-small;
      resize: none;
    }

    &--disabled {
      background-color: rgba(0, 0, 0, 0); // To make the background transparent
      padding-left: 0;
    }
  }
}
</style>
