import type { RouteLocation } from 'vue-router'
import type { QUERY_PARAMETERS } from '~/types/queryParameters'
import {
  PAGE_ACCESS_BY_ROLE,
  ROUTE,
  SOURCE_PERMISSION_PAGE_ACCESS
} from '~/constants/routes'
import type { CurrentUser } from '~/types/user'
import { Dashboard, HOME_MODULES_CONFIG_BY_ID } from '~/constants/homeModules'

export const navigateToParentPage = () => {
  const route = useRoute()
  const router = useRouter()
  const parentRoute = route.matched.slice(-2).shift()
  if (!parentRoute) {
    return
  }
  router.push({ path: parentRoute.path })
}

export const replaceRouteQueryParam = async (
  queryParameters: [
    queryParamKey: QUERY_PARAMETERS,
    queryParamValue: string | undefined
  ][]
) => {
  const route = useRoute()
  const queryParametersToReplace = queryParameters.reduce(
    (queryParametersToReplace, [queryParamKey, queryParamValue]) => ({
      ...queryParametersToReplace,
      ...(route.query[queryParamKey] !== queryParamValue
        ? {
            [queryParamKey]: queryParamValue
          }
        : {})
    }),
    {} as { [key in QUERY_PARAMETERS]?: string | undefined } | {}
  )

  const router = useRouter()
  await router.replace({
    ...route,
    query: {
      ...route.query,
      ...queryParametersToReplace
    }
  })
}

export const cleanQueryParam = async (queryParamKeys: QUERY_PARAMETERS[]) => {
  const queryParametersToClean = queryParamKeys.map(
    queryParamKey => [queryParamKey, undefined] as [QUERY_PARAMETERS, undefined]
  )
  await replaceRouteQueryParam(queryParametersToClean)
}

export const hasUserAccessToPage = (
  user: CurrentUser,
  path: string
): boolean => {
  const hasRoleAccessToPage = PAGE_ACCESS_BY_ROLE[user.role].some(route =>
    path.startsWith(route)
  )

  const hasSourcePermissionAccessToPage =
    user.sourcePermissions && user.sourcePermissions.length > 0
      ? SOURCE_PERMISSION_PAGE_ACCESS.some(route => path.startsWith(route))
      : false

  return (
    ROUTE.ROOT === path ||
    hasRoleAccessToPage ||
    hasSourcePermissionAccessToPage
  )
}

export const getDashboardFromRoute = (
  route: RouteLocation
): Dashboard | null => {
  return (
    (Object.keys(HOME_MODULES_CONFIG_BY_ID) as Dashboard[]).find(key =>
      route.path.includes(HOME_MODULES_CONFIG_BY_ID[key].slug)
    ) || null
  )
}
