import {
  TABLE_ATTRIBUTES,
  TABLE_CELL_ATTRIBUTES,
  TABLE_ROW_ATTRIBUTES
} from '~/constants/bdese'
import type { TableCell, TableData } from '~/types/table'

export const parseHTMLFromString = (stringifiedHTML: string) => {
  const parser = new DOMParser()

  return parser.parseFromString(stringifiedHTML, 'text/html')
}

export const getHtmlFromCustomTable = (tableData: TableData) => {
  const table = document.createElement('table')
  if (tableData.headerRows.length > 0) {
    table.appendChild(getHtmlTableSection(tableData.headerRows, true))
  }

  table.appendChild(getHtmlTableSection(tableData.bodyRows))

  if (tableData.totalRow) {
    table.setAttribute(TABLE_ATTRIBUTES.WITH_TOTAL, 'true')
    table.appendChild(getHtmlTableSection([tableData.totalRow], false, true))
  }
  return table
}

const getHtmlTableSection = (
  tableRows: TableCell[][],
  isTableHead: boolean = false,
  isTableTotal: boolean = false
) => {
  const content = document.createElement(isTableHead ? 'thead' : 'tbody')
  tableRows.forEach(tableRow => {
    content.appendChild(getHtmlRow(tableRow, isTableHead, isTableTotal))
  })
  return content
}

const getHtmlRow = (
  tableCells: TableCell[],
  isHeaderRow: boolean = false,
  isTotalRow: boolean = false
) => {
  const tr = document.createElement('tr')
  tableCells.forEach(tableCell => {
    tr.appendChild(getHtmlCell(tableCell))
  })

  // We need to add a specific attribute to distinguish thead rows from tbody rows because tiptap merge thead rows into tbody
  if (isHeaderRow) {
    tr.setAttribute(TABLE_ROW_ATTRIBUTES.IS_HEADER_ROW, 'true')
  }

  if (isTotalRow) {
    tr.setAttribute(TABLE_ROW_ATTRIBUTES.IS_TOTAL_ROW, 'true')
  }
  return tr
}

const getHtmlCell = (tableCell: TableCell) => {
  const cell = document.createElement(tableCell.isHeader ? 'th' : 'td')
  if (tableCell.attributes?.colSpan) {
    cell.setAttribute('colspan', tableCell.attributes.colSpan)
  }

  if (tableCell.attributes?.initialValue) {
    cell.setAttribute(
      TABLE_CELL_ATTRIBUTES.INITIAL_VALUE,
      tableCell.attributes.initialValue
    )
  }

  // TODO : Check if dimensionsWithValue attribute is necessary for header cells. If not, remove them
  if (
    tableCell.dimensionsWithValue &&
    tableCell.dimensionsWithValue.length > 0
  ) {
    cell.setAttribute(
      TABLE_CELL_ATTRIBUTES.DIMENSIONS_WITH_VALUE,
      JSON.stringify(
        tableCell.dimensionsWithValue.map(dimension => [
          dimension[0].shortTitle,
          dimension[1]
        ])
      )
    )
  }
  cell.innerHTML = `<p>${tableCell.content || ''}</p>`
  return cell
}
