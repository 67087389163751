import { useLegalReportsStore } from './legalReports'
import { DELETE_BDESE_ATTACHMENT_MUTATION } from '~/gql/legalReports/bdese/attachments/deleteBdeseAttachmentMutation'
import { CREATE_BDESE_SECTION_MUTATION } from '~/gql/legalReports/bdese/createBdeseSectionMutation'
import { GET_BDESE_QUERY } from '~/gql/legalReports/bdese/getBdeseQuery'
import { PUBLISH_BDESE_MUTATION } from '~/gql/legalReports/bdese/publishBdeseMutation'
import { REMOVE_BDESE_SECTION_MUTATION } from '~/gql/legalReports/bdese/removeBdeseSectionMutation'
import { SORT_BDESE_SECTIONS_MUTATION } from '~/gql/legalReports/bdese/sortBdeseSectionsMutation'
import { UPDATE_BDESE_SECTION_MUTATION } from '~/gql/legalReports/bdese/updateBdeseSectionMutation'
import type {
  Bdese,
  BdeseAttachment,
  BdeseBaseSection,
  BdeseSection,
  BdeseWithAccessType
} from '~/types/bdese'
import { LegalReportType } from '~/types/legalReports'

interface State {
  fullBdese: Bdese | null
  isDraftVersion: boolean | undefined
  hasEditorAccess: boolean
}

export const useBdeseStore = defineStore('bdese', {
  state: (): State => ({
    fullBdese: null,
    isDraftVersion: undefined,
    hasEditorAccess: false
  }),
  getters: {
    bdese: (state: State) => {
      if (!state.fullBdese) {
        return null
      }

      if (state.isDraftVersion === undefined) {
        return state.fullBdese
      }

      return {
        ...state.fullBdese,
        bdeseSections: state.fullBdese.bdeseSections.filter(
          section => section.isPublished === !state.isDraftVersion
        ),
        bdeseAttachments: state.fullBdese.bdeseAttachments.filter(
          attachment => attachment.isPublished === !state.isDraftVersion
        )
      }
    },
    hasPublishedVersion: (state: State) => {
      if (!state.fullBdese) {
        return false
      }
      return state.fullBdese.bdeseSections.some(section => section.isPublished)
    }
  },
  actions: {
    async getBdese(id: string) {
      const { $apiGqlClient } = useNuxtApp()

      // Reset bdese state
      this.fullBdese = null
      this.isDraftVersion = undefined

      const bdese = (
        await $apiGqlClient.query({
          query: GET_BDESE_QUERY,
          variables: { id }
        })
      ).data.getBdese as BdeseWithAccessType

      this.fullBdese = bdese
      this.hasEditorAccess = bdese.hasEditorAccess
    },
    addAttachments(attachments: BdeseAttachment[]) {
      this.fullBdese!.bdeseAttachments = [
        ...this.fullBdese!.bdeseAttachments,
        ...attachments
      ]
    },
    async deleteAttachment(id: string) {
      const { $apiGqlClient } = useNuxtApp()
      await $apiGqlClient.mutate({
        mutation: DELETE_BDESE_ATTACHMENT_MUTATION,
        variables: {
          id
        }
      })

      const index = this.fullBdese!.bdeseAttachments.findIndex(
        attachment => attachment.id === id
      )!

      this.fullBdese!.bdeseAttachments =
        this.fullBdese!.bdeseAttachments.toSpliced(index, 1)
    },
    replaceAttachment(updatedAttachment: BdeseAttachment) {
      const index = this.fullBdese!.bdeseAttachments.findIndex(
        attachment => attachment.id === updatedAttachment.id
      )!

      this.fullBdese!.bdeseAttachments =
        this.fullBdese!.bdeseAttachments.toSpliced(index, 1, updatedAttachment)
    },
    async updateSection(
      sectionId: string,
      newContent: string,
      newTitle: string
    ) {
      const { $apiGqlClient } = useNuxtApp()

      const updatedBdeseSection = (
        await $apiGqlClient.mutate({
          mutation: UPDATE_BDESE_SECTION_MUTATION,
          variables: {
            id: this.fullBdese!.id,
            sectionId,
            newContent,
            newTitle
          }
        })
      ).data.updateBdeseSection as BdeseSection

      const sectionIndex = this.fullBdese!.bdeseSections.findIndex(
        section => section.id === sectionId
      )!

      this.fullBdese!.bdeseSections[sectionIndex] = updatedBdeseSection
    },
    async addSection(section: BdeseBaseSection) {
      const { $apiGqlClient } = useNuxtApp()

      const updatedBdese = (
        await $apiGqlClient.mutate({
          mutation: CREATE_BDESE_SECTION_MUTATION,
          variables: {
            id: this.fullBdese!.id,
            section
          }
        })
      ).data.createBdeseSection as Bdese

      this.fullBdese = updatedBdese
    },
    async publishNewVersion(
      publicationNote: string | undefined,
      withEmailNotification: boolean
    ) {
      const { $apiGqlClient } = useNuxtApp()
      const { updateAvailableLegalReports } = useLegalReportsStore()
      const updatedBdese = (
        await $apiGqlClient.mutate({
          mutation: PUBLISH_BDESE_MUTATION,
          variables: {
            id: this.fullBdese!.id,
            publicationNote,
            withEmailNotification
          }
        })
      ).data.publishBdese as Bdese

      updateAvailableLegalReports({
        id: updatedBdese.id,
        year: updatedBdese.year,
        type: LegalReportType.BDESE,
        createdAt: updatedBdese.createdAt,
        releasedAt: updatedBdese.publishedAt,
        filters: updatedBdese.bdeseFilters
      })

      this.fullBdese = updatedBdese
    },
    async sortSections(sortedBdeseSections: BdeseSection[]) {
      const { $apiGqlClient } = useNuxtApp()
      const updatedBdese = (
        await $apiGqlClient.mutate({
          mutation: SORT_BDESE_SECTIONS_MUTATION,
          variables: {
            id: this.fullBdese!.id,
            sortedSectionIds: sortedBdeseSections.map(section => section.id)
          }
        })
      ).data.sortBdeseSections as Bdese

      this.fullBdese = updatedBdese
    },
    async removeSection(sectionToRemoveId: string) {
      const { $apiGqlClient } = useNuxtApp()
      const updatedBdese = (
        await $apiGqlClient.mutate({
          mutation: REMOVE_BDESE_SECTION_MUTATION,
          variables: {
            id: this.fullBdese!.id,
            sectionId: sectionToRemoveId
          }
        })
      ).data.removeBdeseSection as Bdese

      this.fullBdese = updatedBdese
    },
    setToDraftVersion() {
      this.isDraftVersion = true
    },
    setToPublishedVersion() {
      this.isDraftVersion = false
    }
  }
})
