import { gql } from 'graphql-tag'
import { BDESE_WITH_ACCESS_TYPE_FRAGMENT } from './bdeseFragment'

export const GET_BDESE_QUERY = gql`
  ${BDESE_WITH_ACCESS_TYPE_FRAGMENT}
  query GET_BDESE($id: String!) {
    getBdese(id: $id) {
      ...BdeseWithAccessTypeFields
    }
  }
`
