<template>
  <div
    v-if="query"
    class="query-renderer"
  >
    <slot
      v-if="rendererState === 'results'"
      :result-set="queryResults"
    />
    <Loader v-if="rendererState === 'loading'" />

    <AnalyticsModuleErrorTemplate
      v-if="rendererState === 'error'"
      :error="error"
    />
  </div>
</template>

<script lang="ts">
import { v4 as uuidv4 } from 'uuid'
import { mapState } from 'pinia'
import type { SlotsType } from 'vue'
import {
  analyticsGetQuery,
  buildCompleteQuery,
  checkQuery
} from '~/services/analytics'
import { ERROR_KEYS_BY_CODE } from '~/constants/errors'
import { type QueryTimeOption } from '~/types/analytics'
import { useAnalyticsStore } from '~/stores/analytics'
import type { PartialQuery, ResultSet } from '~/types/query'

export default {
  name: 'ExtendedQueryRenderer',
  props: {
    query: {
      type: Object as PropType<PartialQuery>,
      required: true
    },
    isBuilder: {
      type: Boolean,
      default: false
    },
    timeOption: {
      type: Object as PropType<QueryTimeOption>,
      required: true
    }
  },
  slots: Object as SlotsType<{
    default: { resultSet: ResultSet }
  }>,
  emits: {
    'success-query': (_: ResultSet) => true,
    'hide-module': (_: Error) => true
  },
  data(): {
    rendererState: 'loading' | 'results' | 'error'
    queryResults: ResultSet | undefined
    error: any
    queryId: string
  } {
    return {
      rendererState: 'loading',
      queryResults: undefined,
      error: undefined,
      queryId: uuidv4()
    }
  },
  computed: {
    ...mapState(useAnalyticsStore, ['analyticsParams']),
    queryParams() {
      return {
        ...this.analyticsParams,
        query: this.query,
        timeOption: this.timeOption
      }
    }
  },
  watch: {
    queryParams: {
      immediate: true,
      deep: true,
      async handler(newParams: typeof this.queryParams, oldParams: any) {
        if (JSON.stringify(oldParams) === JSON.stringify(newParams)) {
          return
        }

        const {
          query,
          scopeType,
          selectedPeriod,
          timeOption,
          allAppliedFilters
        } = newParams
        this.rendererState = 'loading'
        if (!query) {
          return
        }

        const completeQuery = buildCompleteQuery(
          query,
          timeOption,
          selectedPeriod,
          allAppliedFilters
        )

        useAnalyticsStore().addOrUpdateQuery(this.queryId, completeQuery)

        try {
          // patch to prevent specific measure to be requested with timeDimensions
          checkQuery(completeQuery)

          const queryResults = await analyticsGetQuery(completeQuery, scopeType)

          if (JSON.stringify(newParams) === JSON.stringify(this.queryParams)) {
            this.queryResults = queryResults
            this.rendererState = 'results'
            if (this.isBuilder) {
              this.$emit('success-query', queryResults)
            }
          }
        } catch (error: any) {
          if (JSON.stringify(newParams) === JSON.stringify(this.queryParams)) {
            this.rendererState = 'error'

            // The component displays the error in Builder, otherwise, the error is displayed in the module
            if (!this.isBuilder) {
              this.$emit('hide-module', error as Error)
            }
            this.error = error

            if (
              ![
                ERROR_KEYS_BY_CODE.ANALYTICS_PERMISSION_DENIED,
                ERROR_KEYS_BY_CODE.ANALYTICS_INAPPLICABLE_MEASURES,
                ERROR_KEYS_BY_CODE.ANALYTICS_INAPPLICABLE_FILTERS,
                ERROR_KEYS_BY_CODE.ANALYTICS_INAPPLICABLE_DIMENSIONS,
                ERROR_KEYS_BY_CODE.ANALYTICS_INAPPLICABLE_TEMPORAL_DIMENSION
              ].some(errorKey => (error.message as string).includes(errorKey))
            ) {
              throw error
            }
          }
        }
      }
    }
  },
  beforeUnmount() {
    useAnalyticsStore().removeQuery(this.queryId)
  }
}
</script>

<style lang="scss" scoped>
.query-renderer {
  display: flex;
  flex-direction: column;
  flex: 1;
}
</style>
