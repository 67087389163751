import { gql } from 'graphql-tag'

const BDESE_SECTION_FIELDS = `
  id
  order
  content
  title
  description
  isPublished
`

const BDESE_FIELDS = `
  id
  year
  createdAt
  publishedAt
  bdeseSections {
    ${BDESE_SECTION_FIELDS}
  }
  bdeseFilters {
    member
    operator
    values
  }
  realizedYearsNumber
  trendYearsNumber
  isFiscal
  bdeseAttachments {
    id
    attachmentId
    filename
    uploadedAt
    size
    isPublished
  }
`

export const BDESE_FRAGMENT = gql`
  fragment BdeseFields on Bdese {
    ${BDESE_FIELDS}
  }
`

export const BDESE_WITH_ACCESS_TYPE_FRAGMENT = gql`
  fragment BdeseWithAccessTypeFields on BdeseWithAccessTypeDto {
    ${BDESE_FIELDS}
    hasEditorAccess
  }
`

export const BDESE_SECTION_FRAGMENT = gql`
  fragment BdeseSectionFields on BdeseSection {
    ${BDESE_SECTION_FIELDS}
  }
`
