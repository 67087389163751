import type { DataType } from './analytics'

export enum CustomizationField {
  EMPLOYEE_STANDARD_1 = 'EMPLOYEE_STANDARD_1',
  EMPLOYEE_STANDARD_2 = 'EMPLOYEE_STANDARD_2',
  EMPLOYEE_STANDARD_3 = 'EMPLOYEE_STANDARD_3',
  EMPLOYEE_GROUP = 'EMPLOYEE_GROUP',
  RECRUITMENT_STANDARD_1 = 'RECRUITMENT_STANDARD_1',
  RECRUITMENT_STANDARD_2 = 'RECRUITMENT_STANDARD_2',
  RECRUITMENT_STANDARD_3 = 'RECRUITMENT_STANDARD_3',
  RECRUITMENT_GROUP = 'RECRUITMENT_GROUP',
  IS_RECRUITMENT_AVAILABLE = 'IS_RECRUITMENT_AVAILABLE',
  HAS_TRIAL_PERIOD_DATA = 'HAS_TRIAL_PERIOD_DATA',
  HAS_MANAGER_DATA = 'HAS_MANAGER_DATA',
  IS_DASHBOARD_IN_FISCAL_MODE = 'IS_DASHBOARD_IN_FISCAL_MODE',
  FISCAL_YEAR_START_MONTH = 'FISCAL_YEAR_START_MONTH',
  ABSENTEEISM_TYPE_NORMALIZATION = 'ABSENTEEISM_TYPE_NORMALIZATION',
  DEPARTURE_TYPE_NORMALIZATION = 'DEPARTURE_TYPE_NORMALIZATION'
}

export enum CustomizationFieldType {
  STANDARD_FILTER = 'STANDARD_FILTER',
  MAIN_GROUP_DIMENSION = 'MAIN_GROUP_DIMENSION',
  TOGGLE = 'TOGGLE',
  MONTH = 'MONTH'
}

export enum CustomizationCategory {
  EMPLOYEE_DASHBOARD = 'EMPLOYEE_DASHBOARD',
  RECRUITMENT_DASHBOARD = 'RECRUITMENT_DASHBOARD',
  SETUP = 'SETUP'
}

export type Customizations = {
  [key in CustomizationField]: string
}

export type CustomizationFieldWithOptions = {
  field: CustomizationField
  options: string[]
  type: CustomizationFieldType
  value: string
  hasUserEditionAccess: boolean
}

export type CustomizationFieldsByCategory = {
  [key in CustomizationCategory]: {
    fields: CustomizationFieldWithOptions[]
    isEditable: boolean
    dataType: DataType
  }
}

export enum CustomizationFieldSetupOptions {
  TRUE = 'TRUE',
  FALSE = 'FALSE'
}
