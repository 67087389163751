import { createI18n } from 'vue-i18n'
import fr from '~/locales/fr.json'
import en from '~/locales/en.json'
import { AvailableLocale } from '~/types/locale'
import { getAppLocale } from '~/services/locale'

type MessageSchema = typeof en & typeof fr

type DotPrefix<T extends string, U extends string> = `${T}.${U}`

type NestedKeys<T> = {
  [K in keyof T]: T[K] extends Record<string, any>
    ? K extends string
      ? DotPrefix<K, NestedKeys<T[K]>>
      : never
    : K extends string
    ? K
    : never
}[keyof T]

export type TranslationKeys = NestedKeys<MessageSchema>

export default defineNuxtPlugin(({ vueApp }) => {
  const i18n = createI18n<[MessageSchema], AvailableLocale>({
    legacy: false,
    locale: getAppLocale(),
    fallbackLocale: AvailableLocale.EN,
    messages: {
      [AvailableLocale.EN]: en,
      [AvailableLocale.FR]: fr
    }
  })

  vueApp.use(i18n)

  return {
    provide: {
      i18n,
      t: i18n.global.t,
      te: i18n.global.te
    }
  }
})
