<template>
  <div class="score__container">
    <ReflectIconButton
      class="container__help"
      icon="icon-info"
      @click="openInformationModal('egaproIndexScore')"
    />
    <div class="container__total">
      <div>
        <div class="total__total-score">
          <p class="total-score__numerator">
            {{
              egaproIndex.isComputed ? egaproIndex.totalScore : UNDEFINED_SCORE
            }}
          </p>
          <p class="total-score__denominator">
            {{ ` / ${EGAPRO_INDEX_SCORE_BASE.TOTAL}` }}
          </p>
        </div>
        <div class="total__evolution">
          <ReflectEvolution
            v-if="egaproIndex.previousYearTotalScore && egaproIndex.isComputed"
            :is-favorable="true"
            :period-type="PeriodType.YEAR"
            :format="MemberFormat.NUMBER_0"
            :stats="evolutionStat"
          />
        </div>
      </div>
      <ReflectButton
        class="total__button"
        :disabled="!egaproIndex.isComputed"
        @click="downloadResultFile"
      >
        <img
          class="button__logo"
          src="~/assets/images/logo-excel.svg"
        />
        {{ $t('legalReports.egaproIndex.indexScore.downloadDetails') }}
      </ReflectButton>
    </div>

    <div class="container__categories">
      <div
        v-for="scoreDetail in scoreDetails"
        :key="scoreDetail.translationKey"
        class="container__category"
      >
        <div class="category">
          <p class="category__title">
            {{
              $t(
                `legalReports.egaproIndex.indexScore.${scoreDetail.translationKey}`
              )
            }}
          </p>
          <div class="category__score">
            <template
              v-if="egaproIndex.isComputed && scoreDetail.score === null"
            >
              <p class="score__numerator">
                {{ NON_APPLICABLE_SCORE }}
              </p>
            </template>
            <template v-else>
              <p class="score__numerator">
                {{
                  egaproIndex.isComputed ? scoreDetail.score : UNDEFINED_SCORE
                }}
              </p>
              <p class="score__denominator">
                {{ ` / ${scoreDetail.base}` }}
              </p>
            </template>
          </div>
        </div>
        <ReflectIconButton
          icon="icon-info"
          @click="openInformationModal(scoreDetail.translationKey)"
        />
      </div>
    </div>
  </div>
  <Teleport
    v-if="isInformationModalOpen"
    to="#action"
  >
    <ReflectModal
      :title-message="
        $t(
          `legalReports.egaproIndex.informationModal.${informationModalTranslationKey}.title`
        )
      "
      @cancel="closeInformationModal"
    >
      <LegalReportsEgaproContentInfoModal
        :has-link="informationModalTranslationKey === 'egaproIndexScore'"
        :translation-key="informationModalTranslationKey!"
      />
    </ReflectModal>
  </Teleport>
</template>

<script lang="ts">
import { type EgaproIndexWithPreviousYearScore } from '~/types/egaproIndex'
import { PeriodType } from '~/types/timeDimension'
import { DOWNLOAD_RESULT_FILE } from '~/gql/legalReports/egaproIndex/downloadResultFile'
import { EGAPRO_INDEX_SCORE_BASE } from '~/constants/egaproIndex'
import { MemberFormat } from '~/types/analyticsFormat'

const UNDEFINED_SCORE = '?'
const NON_APPLICABLE_SCORE = 'N/A'

export default {
  name: 'EgaproIndexScore',
  props: {
    egaproIndex: {
      type: Object as PropType<EgaproIndexWithPreviousYearScore>,
      required: true
    },
    isSmallCompany: {
      type: Boolean,
      required: false
    }
  },
  setup() {
    return {
      PeriodType,
      UNDEFINED_SCORE,
      EGAPRO_INDEX_SCORE_BASE,
      NON_APPLICABLE_SCORE,
      MemberFormat
    }
  },
  data(): {
    isInformationModalOpen: boolean
    informationModalTranslationKey: string | undefined
  } {
    return {
      isInformationModalOpen: false,
      informationModalTranslationKey: undefined
    }
  },
  computed: {
    scoreDetails() {
      return [
        {
          translationKey: 'genderPayGap',
          score: this.egaproIndex.genderPayGapScore,
          base: EGAPRO_INDEX_SCORE_BASE.GENDER_PAY_GAP
        },
        {
          translationKey: 'genderPayRaiseGap',
          score: this.isSmallCompany
            ? this.egaproIndex.smallCompanyGenderPayRaiseGapScore
            : this.egaproIndex.largeCompanyGenderPayRaiseGapScore,
          base: this.isSmallCompany
            ? EGAPRO_INDEX_SCORE_BASE.SMALL_COMPANY_GENDER_PAY_RAISE_GAP
            : EGAPRO_INDEX_SCORE_BASE.LARGE_COMPANY_GENDER_PAY_RAISE_GAP
        },
        ...(this.isSmallCompany
          ? []
          : [
              {
                translationKey: 'genderPromotionGap',
                score: this.egaproIndex.largeCompanyGenderPromotionGapScore,
                base: EGAPRO_INDEX_SCORE_BASE.GENDER_PROMOTION_GAP
              }
            ]),
        {
          translationKey: 'payRaiseAfterMaternityLeave',
          score: this.egaproIndex.payRaiseAfterMaternityLeaveScore,
          base: EGAPRO_INDEX_SCORE_BASE.PAY_RAISE_AFTER_MATERNITY_LEAVE
        },
        {
          translationKey: 'topHighestSalaries',
          score: this.egaproIndex.topHighestSalariesScore,
          base: EGAPRO_INDEX_SCORE_BASE.TOP_HIGHEST_SALARIES
        }
      ]
    },
    evolutionStat() {
      if (this.egaproIndex.previousYearTotalScore) {
        return [
          {
            evolution:
              this.egaproIndex.totalScore! -
              this.egaproIndex.previousYearTotalScore,
            value: this.egaproIndex.totalScore!
          }
        ]
      } else {
        return []
      }
    }
  },
  methods: {
    async downloadResultFile() {
      const resultFileUrl = (
        await this.$apiGqlClient.query({
          query: DOWNLOAD_RESULT_FILE,
          variables: {
            id: this.egaproIndex.id
          }
        })
      ).data.downloadResultFile as string

      const aElement = document.createElement('a')
      aElement.href = resultFileUrl
      aElement.click()
    },
    openInformationModal(translationKey: string) {
      this.informationModalTranslationKey = translationKey
      this.isInformationModalOpen = true
    },
    closeInformationModal() {
      this.informationModalTranslationKey = undefined
      this.isInformationModalOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>
.score__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .container {
    &__title {
      @include font-text($font-weight-medium);
      @include font-size($font-size-normal, 28px);
      color: $text-secondary;
      display: flex;
      align-items: center;
      margin-bottom: 6px;
    }

    &__help {
      position: absolute;
      top: $margin-regular;
      right: $margin-small;
      display: flex;
    }

    &__total {
      display: flex;
      margin-bottom: $margin-medium;
      align-items: end;

      .total {
        &__total-score {
          display: flex;
          align-items: end;

          .total-score {
            &__numerator {
              @include font-title;
              @include font-size($font-size-large);
              margin-right: 4px;
            }

            &__denominator {
              @include font-title;
              @include font-size($font-size-intermediate, 42px);
            }
          }
        }

        &__evolution {
          height: 24px;
        }

        &__button {
          margin-left: auto;

          .button {
            &__logo {
              margin-right: 4px;
            }
          }
        }
      }
    }

    &__categories {
      display: flex;
      flex-direction: column;
      gap: $margin-regular;
    }

    &__category {
      display: flex;
      align-items: center;

      .category {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-right: $margin-tiny;

        &__title {
          @include font-text($font-weight-book);
          @include font-size($font-size-regular);
          color: $text-tertiary;
          white-space: pre-line;
        }

        &__score {
          display: flex;
          align-items: end;
          margin-right: $margin-intermediate;

          .score {
            &__numerator {
              @include font-title;
              @include font-size($font-size-medium);
              margin-right: 4px;
            }

            &__denominator {
              @include font-title;
              @include font-size($font-size-regular, 34px);
            }
          }
        }
      }
    }
  }
}
</style>
