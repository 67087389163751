<template>
  <div
    class="list-renderer-container"
    :class="{ 'list-renderer-container--is-in-modal': isInModal }"
  >
    <AnalyticsExtendedQueryRenderer
      :time-option="timeOption"
      :query="paginatedQuery"
      :is-builder="true"
      @success-query="handleQuerySuccess"
    >
      <template #default="{ resultSet }">
        <AnalyticsRendererList
          v-if="query.measures.length > 0 && resultSet"
          :is-in-modal="isInModal"
          :result-set="resultSet"
          :columns="columns"
          :sorting-by-columns="sortingByColumns"
          @columns-sorting-update="updateOrderInQuery"
        />
        <slot
          name="columnCustomization"
          :result-set="resultSet"
        />
      </template>
    </AnalyticsExtendedQueryRenderer>
    <ReflectPagination
      v-if="queryResultSet"
      :total="queryResultSet.results[0]!.total!"
      :max-items-per-page="MAX_ITEMS_PER_PAGE"
      :selected-page-index="pageIndex"
      :class="{ 'list-renderer-container__pagination': !isInModal }"
      @page-index-update="handlePageIndexUpdate"
    />
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia'
import type { SlotsType } from 'vue'
import { getColumns } from '~/services/explore'
import { type Order, OrderValue } from '~/types/cube'
import { type SortingByColumn, type QueryTimeOption } from '~/types/analytics'
import { QUERY_PARAMETERS } from '~/types/queryParameters'
import { DATA_EXPORT_TYPE } from '~/types/dataExport'
import { exportListData } from '~/services/dataExport'
import type { Executable, ListExplorerQuery, ResultSet } from '~/types/query'
import { useAnalyticsStore } from '~/stores/analytics'

export const MAX_ITEMS_PER_PAGE = 50

export default {
  name: 'ListQueryRenderer',
  props: {
    query: {
      type: Object as PropType<Executable<ListExplorerQuery>>,
      required: true
    },
    timeOption: {
      type: Object as PropType<QueryTimeOption>,
      required: true
    },
    includeMeasureAsColumn: {
      type: Boolean,
      default: false
    },
    isInModal: {
      type: Boolean,
      default: false
    },
    shouldUseRouteQueryParams: {
      type: Boolean,
      default: false
    }
  },
  slots: Object as SlotsType<{
    columnCustomization: { resultSet: ResultSet }
  }>,
  emits: ['success-query', 'loading-query'],
  setup() {
    return {
      MAX_ITEMS_PER_PAGE
    }
  },
  data(): {
    queryResultSet: ResultSet | null
    pageIndex: number
    queryOrder: Order[] | null
  } {
    return {
      queryResultSet: null,
      pageIndex: 0,
      queryOrder: null
    }
  },
  computed: {
    ...mapState(useAnalyticsStore, ['analyticsParams']),
    queryWithOrder() {
      return {
        ...this.query,
        order: this.queryOrder
          ? this.getFullQueryOrder(this.query, this.queryOrder)
          : this.getFullQueryOrder(this.query, this.query.order || [])
      }
    },
    paginatedQuery() {
      return {
        ...this.queryWithOrder,
        total: true,
        offset: this.pageIndex * MAX_ITEMS_PER_PAGE,
        limit: MAX_ITEMS_PER_PAGE
      }
    },
    columns() {
      if (!this.queryResultSet) {
        return []
      }
      return getColumns(this.queryResultSet, this.includeMeasureAsColumn)
    },
    sortingByColumns() {
      return this.columns.reduce((sortingByColumns, column) => {
        const explicitOrder = this.queryOrder?.find(
          order => order[0] === column.shortTitle
        )

        sortingByColumns[column.shortTitle] = explicitOrder
          ? explicitOrder[1]
          : null
        return sortingByColumns
      }, {} as SortingByColumn)
    }
  },
  watch: {
    analyticsParams: {
      deep: true,
      handler(
        newParams: typeof this.analyticsParams,
        oldParams: typeof this.analyticsParams
      ) {
        if (JSON.stringify(oldParams) === JSON.stringify(newParams)) {
          return
        }
        this.queryResultSet = null
        this.queryOrder = null
        this.pageIndex = 0
        this.$emit('loading-query')
      }
    }
  },
  created() {
    this.pageIndex = this.getInitialPageIndex()
    this.queryOrder = this.getInitialQueryOrder()
  },
  methods: {
    getFullQueryOrder(
      query: Executable<ListExplorerQuery>,
      queryOrder: Order[]
    ) {
      const explicitlyOrderedDimensionTitles = queryOrder.map(order => order[0])

      return [
        ...queryOrder,
        ...query.dimensions
          .filter(
            dimensionTitle =>
              !explicitlyOrderedDimensionTitles.includes(dimensionTitle)
          )
          .map(dimensionTitle => [dimensionTitle, OrderValue.ASC] as Order)
      ]
    },
    handleQuerySuccess(resultSet: ResultSet) {
      this.queryResultSet = resultSet
      if (resultSet.results[0]!.data?.length > 0) {
        this.$emit('success-query')
      }
    },
    updateOrderInQuery(sortingByColumns: SortingByColumn) {
      this.handlePageIndexUpdate(0)

      const explicitlyFilteredColumns = Object.keys(sortingByColumns)
        .filter(dimensionTitle => sortingByColumns[dimensionTitle] !== null)
        .map(
          dimensionTitle =>
            [dimensionTitle, sortingByColumns[dimensionTitle]] as Order
        )

      this.queryOrder = explicitlyFilteredColumns
    },
    /** @public : This method is called from parent component */
    async exportData(exportType: DATA_EXPORT_TYPE) {
      await exportListData(
        this.queryWithOrder,
        this.includeMeasureAsColumn,
        exportType
      )
    },
    getInitialPageIndex() {
      if (!this.shouldUseRouteQueryParams) {
        return 0
      }
      const page = useRoute().query[QUERY_PARAMETERS.EXPLORER_PAGE]

      if (!page) {
        return 0
      }
      const pageQueryParam = parseFloat(page as string)
      if (!isNaN(pageQueryParam)) {
        return pageQueryParam - 1
      } else {
        return 0
      }
    },
    getInitialQueryOrder(): Order[] | null {
      if (!this.shouldUseRouteQueryParams) {
        return null
      }

      const rawQueriedOrderedColumns =
        useRoute().query[QUERY_PARAMETERS.EXPLORER_ORDERED_COLUMNS]

      if (!rawQueriedOrderedColumns) {
        return null
      }

      return JSON.parse(decodeURIComponent(rawQueriedOrderedColumns as string))
    },
    handlePageIndexUpdate(pageIndex: number) {
      this.pageIndex = pageIndex
    }
  }
}
</script>

<style lang="scss" scoped>
.list-renderer-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  &--is-in-modal {
    min-height: 180px;
  }

  &__pagination {
    border-top: 1px solid $border-ternary;
  }
}
</style>
